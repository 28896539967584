/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 1.6;
}

img {
  display: block;
  max-width: 100%;
}

.app-container {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: min-content auto min-content min-content;
  grid-template-areas:
    "input"
    "..."
    "output"
    "links";
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30%;
}

.attribution-links-container {
  grid-area: links;
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: .5em;
}

a {
  color: black;
  font-size: .75rem;
}

@media (max-width: 700px) {
  .app-container {
    background-position: 10%;
  }
}

@media (max-width: 550px) {
  .app-container {
    background-position: 20%;
  }
}

@media (max-width: 400px) {
  .app-container {
    background-position: 25%;
  }
}

/* INPUT BOX */

.input-box-container {
  grid-area: input;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 65% 1fr;
  grid-gap: .25em;
  justify-items: center;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.input {
  grid-row: 1;
  grid-column: 1;
  align-self: end;
  font-family: "Quintessential", cursive;
  font-size: 1rem;
  width: 70%;
  height: 75%;
  resize: none;
  background-color: transparent;
  border: none;
  z-index: 1;
  scrollbar-color: rgb(80, 35, 10) transparent;
  scrollbar-width: thin;
}

.input::-webkit-scrollbar {
  width: .25em;
  height: 0;
}

.input::-webkit-scrollbar-track {
  background: none;
}

.input::-webkit-scrollbar-thumb {
  background: rgb(80, 35, 10);
  border: none;
}

.button {
  grid-row: 2;
  grid-column: 1;
  align-self: start;
  font-family: "Quintessential", cursive;
  font-size: 1rem;
  color: rgb(80, 35, 10);
  background: none;
  border: 3px solid rgb(80, 35, 10);;
  padding: 0 .75em;
  z-index: 1;
}

.button.hovered {
  color: white;
  background: rgb(80, 35, 10);
}

.scroll {
  grid-row: 1 / 3;
  grid-column: 1;
  width: 100%;
  z-index: 0;
}

@media (min-width: 500px) {
  .input,
  .button {
    font-size: 1.25rem;
  }
}

/* OUTPUT BOX */

.output-box-container {
  grid-area: output;
  background: rgba(255, 255, 255, 75%);
  border: 3px solid rgba(0, 0, 255, 25%);
  border-radius: 25px;
  width: 90%;
  max-width: 800px;
  min-height: 8em;
  padding: 1em;
}

.output-text {
  font-family: "Comic Sans MS", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (min-width: 500px) {
  .output-text {
    font-size: 1.125rem;
  }
}